import $ from 'jquery';
import { attachIntersectionObserver, addScrollListener, addResizeListener } from './utils.js';

import images from '../img/index.js';

import './cookies.js';
import './email.js';

$('#hamburger').on('click', function() {
	$('.navbox').removeClass('hidden');
	$('.navbox-overlay').removeClass('hidden');
	console.log('disable scroll');
	$('body').addClass('no-scroll');

	function closeHandler() {
		$('.navbox').addClass('hidden');
		$('.navbox-overlay').addClass('hidden').off('click');
		$('.navbox__close').off('click');
		$('body').removeClass('no-scroll');
	}
	$('.navbox-overlay').on('click', closeHandler);
	$('.navbox__close').on('click', closeHandler);
});

$('.navbox__item').on('click', function() {
	// get next sibling (use selector to check if it's a submenu
	var submenu = $(this).next('.navbox__submenu');
	if (submenu.length) {
		submenu.toggleClass('collapsed');
		$(this).toggleClass('collapsed');
	}
});

$('.dropdown').on('click', function() {
	var item = $(this);

	function blurHandler(e) {
		// collapse the dropdown if the mouse leaves the dropdown or
		// the element (and its children) lose focus
		var target = e.relatedTarget || e.toElement;
		if (target && target !== this && !$.contains(item[0], target)) {
			// detach event listeners
			item.off('mouseout');
			item.off('focusout');
			// collapse dropdown
			item.addClass('collapsed');
		}
	}

	if (item.hasClass('collapsed')) {
		// show dropdown
		item.removeClass('collapsed');
		// attach event listeners
		item.on('mouseout', blurHandler);
		// focusout is a bubbling "blur" event (see https://api.jquery.com/focusout/)
		item.on('focusout', blurHandler);
	} else {
		item.addClass('collapsed');
		item.off('mouseout');
		item.off('focusout');
	}
}).on('keydown', function(e) {
	var item = $(this);
	// stop event bubbling
	e.stopPropagation();
	if (e.which === 13) {
		// enter pressed, emulate click event
		e.target.click();
	} else if (e.which === 27) {
		// ESC pressed, collapse dropdown
		item.addClass('collapsed');
		item.off('mouseout');
		item.off('focusout');
	}
});

$('#mailto').on('click', function() {
	var link = document.createElement('a');
	link.href = atob('bWFpbHRvOm1haWxAcGNiLWFydHMuY29t');
	link.click();
});

// add spinner to image
$('.load-image--initial, .load-image--lazy').each(function() {
	$(this).html('<div class="load-image__spinner"></div>');
});


function loadImage(target) {
	var $target = $(target);
	var src = $target.attr('data-src');

	function removeClass() {
		$target.removeClass('load-image--initial').removeClass('load-image--lazy');	
	}
	
	// check if image exists
	if (src in images) {
		src = images[src];
	} else {
		console.error('Cannot load image', src);
		return true;
	}

	var ext = src.split('.').pop();
	if (ext === 'svg') {
		// load SVG as XML document using an AJAX request
		$.get(src, data => {
			var $svg = $(data).find('svg');
			removeClass();
			$target.append($svg);
		}, 'xml');
	} else {
		// other wise treat as image	
		var image = new Image();
		image.onload = () => {
			// completed loading image
			removeClass();
			$target.css('background-image', 'url(' + image.src + ')');
		};
		image.onerror = () => {
			// try again?
			removeClass();
			console.log('error');
		};
		// start load process
		image.src = src;
	}
}

$('.load-image--initial').each(function() {
	loadImage(this);
});
attachIntersectionObserver($('.load-image--lazy'), entry => {
	if (entry.intersectionRatio > 0) {
		loadImage(entry.target);
		// detach scroll listener 
		return true;
	}
});

function resetHorizontalScroll() {
	// when the browser changes the orientation, the horizontal scroll offset
	// might be changed. Since overflow-x is set to hidden, the user can't change
	// the offset back and the page is dislocated.
	//$(window).scrollLeft(0);
}
addResizeListener(resetHorizontalScroll);
addScrollListener(resetHorizontalScroll);


// tranlate links in development env
if (process.env.NODE_ENV === 'development') {
	const re = /^\/(de|en)\/([A-z_]*)$/

	console.log('test-dev');
	$('a').each(function() {
		var match = re.exec($(this).attr('href'));
		if (match) $(this).attr('href', '/' + match[2] + (match[2] !== '' ? '.html' : 'index.html'));
	});
}

attachIntersectionObserver($('[scroll-effect="fade-in"]'), entry => {
	if (entry.intersectionRatio > 0.5) {
		$(entry.target).addClass('fade-in');
		return true;
	}
});
