import $ from 'jquery';

var scrollDebounce = false,
	scrollListeners = [];

export function addScrollListener(callback, init) {
	// add new callback in front for FIFO
	scrollListeners.unshift(callback);
	if (init) callback();
}

function resetScrollDebounce() {
	scrollDebounce = false;
}

window.addEventListener('scroll', function() {
	// debounce
	if (scrollDebounce) return;

	// reset `scrollDebounce` before next repaint
	requestAnimationFrame(resetScrollDebounce);

	for (var i = scrollListeners.length - 1; i >= 0; --i) {
		scrollListeners[i]();
	}

	scrollDebounce = true;
}, { passive: true });


var resizeDebounce = false,
	resizeListeners = [];

export function addResizeListener(callback, init) {
	// insert callback in first position for FIFO
	resizeListeners.unshift(callback);
	if (init) callback();
}
function resetResizeDebounce() {
	resizeDebounce = false;
}
window.addEventListener('resize', function() {
	// debounce
	if (resizeDebounce) return;

	// reset `resizeDebounce` before next repaint
	requestAnimationFrame(resetResizeDebounce);

	for (var i = resizeListeners.length - 1; i >= 0; --i) {
		resizeListeners[i]();
	}

	resizeDebounce = true;
}, { passive: true });

export function attachIntersectionObserver(items, callback) {
	// TODO: remove ".lazy" class if `IntersectionObserver` is not supported
	var observer = new IntersectionObserver(entries => {
		for (var i = 0; i !== entries.length; ++i) {
			if (callback(entries[i])) {
				// if the callback returns true, detach the observer
				observer.unobserve(entries[i].target);
			}
		}
	}, { threshold: [ 0, 0.5, 1 ] });
	items.each(function() {
		observer.observe(this);	
	});
}

export function createSVGElement(type) {
	return $(document.createElementNS('http://www.w3.org/2000/svg', type));
}
