import $ from 'jquery';

const blocked_pages = ['impressum', 'privacy_policy'];

export var consent = getConsent() || 'essential';

function setConsent(settings) {
	consent = settings;
	maybeInsertGA();
	
	// When cookies are blocked, accessing localStorage throws an DOMException
	try {
		localStorage.setItem('cookie-consent', settings);
	} catch (e) {
		// TODO: tell the user that the settings couldn't be saved?
	}
}

function getConsent() {
	try {
		return localStorage.getItem('cookie-consent');
	} catch (e) {
		// no consent by default
		return null;
	}
}

function showBanner() {
	// disable body scrolling
	$('body').addClass('no-scroll');
	// show banner and overlay
	$('.cookie-banner').removeClass('hidden');
	$('.cookie-overlay').removeClass('hidden');

	// obtain accepted cookie list from localStorage
	var accepted_cookies = getConsent() || 'essential';
	// convert to Array
	accepted_cookies = accepted_cookies.split(',');
	// set checkboxes according current cookie settings
	$('.cookie-banner__checkbox').each(function() {
		var type = $(this).attr('data-type');
		this.checked = accepted_cookies.indexOf(type) !== -1;
	});
}

function hideBanner() {
	// enable body scrolling
	$('body').removeClass('no-scroll');
	// hide banner and overlay
	$('.cookie-banner').addClass('hidden');
	$('.cookie-overlay').addClass('hidden');
}

$('#btn-cookie-accept-selected').on('click', () => {
	var accepted_cookies = [];
	$('.cookie-banner__checkbox').each(function() {
		var type = $(this).attr('data-type');
		if (this.checked) accepted_cookies.push(type);
	});
	setConsent(accepted_cookies.join(','));
	hideBanner();
});

$('#btn-cookie-accept-all').on('click', () => {
	setConsent('essential,statistics');
	hideBanner();
});

$('#cookie-settings').on('click', e => {
	// prevent link from reloading the page
	e.preventDefault();
	showBanner();
});

// don't show banner on blocked pages (privacy_policy, impressum)
if (blocked_pages.indexOf($('body').attr('data-page')) === -1) {
	if (getConsent() === null) {
		// no preferences set yet, show consent banner
		showBanner();
	}
}

var ga_script = false;

function maybeInsertGA() {
	// insert the google analytics script, if the user accepted statistics cookies
	if (consent.indexOf('statistics') !== -1) {
		window['ga-disable-G-7GGPCB70LF'] = false;
		// insert script
		if (ga_script) return;

		$('body').append('<script async src="https://www.googletagmanager.com/gtag/js?id=G-7GGPCB70LF"></script>');
		// insert salesviewer

		var salesviewer_string = ` <script defer="true">
			(function(w,d,s,l,i){
				var g=w[l] ? '&s='+w[l]:'', f=d.getElementsByTagName(s)[0], j=d.createElement(s);
				j.async=true,j.src='https://dntfctn.com/stm.js?id='+i+g;
				j.referrerPolicy='no-referrer-when-downgrade';
				f.parentNode.insertBefore(j,f);
			})(window,document,'script','name','Q6x1m4J8F9i1')
		</script>`

		$('body').append(salesviewer_string);

		ga_script = true;
	} else {
		window['ga-disable-G-7GGPCB70LF'] = true;
	}
}
maybeInsertGA();
